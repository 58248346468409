'use client';
import React from 'react';
import useGsapLoaded from '@src/hooks/useGsapLoaded';

const useHumanTrakTilesAnimate = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const hasPlayedRef = React.useRef(false);
  const isGsapLoaded = useGsapLoaded();

  React.useEffect(() => {
    if (!isGsapLoaded || !containerRef.current) return;

    const gsap = window.gsap;
    gsap.registerPlugin(window.ScrollTrigger);

    // Immediately set opacity to 0 when the component mounts
    if (containerRef.current) {
      const svgElements = containerRef.current.querySelectorAll('.humantrak-tile-animate');
      svgElements.forEach((el) => ((el as HTMLElement).style.opacity = '0'));
    }

    const ctx = gsap.context(() => {
      const svgElements = containerRef.current?.querySelectorAll('.humantrak-tile-animate');

      // Create ScrollTrigger for both video and SVGs
      const trigger = window.ScrollTrigger.create({
        trigger: containerRef.current,
        start: 'top center',
        onEnter: () => {
          if (!hasPlayedRef.current && videoRef.current) {
            videoRef.current.play();
            hasPlayedRef.current = true;
          }
        },
      });

      // Animate SVGs
      gsap.to(svgElements, {
        opacity: 1,
        duration: 3,
        delay: 2,
        scrollTrigger: trigger,
      });

      return () => {
        trigger.kill();
      };
    }, containerRef);

    return () => ctx.revert();
  }, [isGsapLoaded]);

  return { containerRef, videoRef };
};

export default useHumanTrakTilesAnimate;
