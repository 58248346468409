'use client';

import React from 'react';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import HeroValdProd from '@src/components/Sections/HeroValdProd';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import HotSpots from '@src/components/Sections/HotSpots';
import ImageTextSecondary from '@src/components/Sections/ImageTextSecondary';
import FeaturedProducts from '@src/components/Sections/FeaturedProducts';
import CallToAction from '@src/components/Sections/CallToAction';
import HumanTrakVideo from '@src/components/Sections/HumanTrakVideo/HumanTrakVideo';
import usePreviewContent from '@src/hooks/usePreviewContent';
import constructURL from '@src/utils/constructURL';
import { HumanTrakPageClientPropTypes } from './HumanTrakPage.types';
const HumanTrakPageClient: React.FC<HumanTrakPageClientPropTypes> = ({
  data
}) => {
  // Enable live updates for the post
  const page = usePreviewContent({
    content: data?.page
  });

  // Set the page view path for analytics tracking
  const {
    setPathPageView,
    setContentType
  } = useTrackingCode();
  setPathPageView(constructURL({
    languageCode: page?.languageCode?.toLowerCase(),
    slug: `/products/humantrak`,
    baseURL: ''
  }));
  setContentType('site-page');
  return <>
      <HeroValdProd id={page?.hero?.items?.[0]?.scrollTo?.slice(1)} ctfData={page?.hero?.items?.[0]} wrapClasses='!mt-[2.4rem] lg:!mt-[0] 3xl:!mt-[5rem] !mb-[0rem] lg:!pb-[3rem] overflow-hidden min-h-[46rem] sm:min-h-[53rem] md:!min-h-[53rem] lg:!min-h-[50rem] 2xl:!min-h-[65rem]' imgClassName='sm:!absolute sm:w-[50rem] lg:w-[60rem] 2xl:w-[80rem] 3xl:w-[100rem] sm:right-[0] top-[5rem] sm:top-[20rem] lg:top-[10rem] 2xl:top-[12rem] 3xl:top-[0]' logoClassName='mt-[3rem] md:mt-[5.7rem] max-w-[27.6386rem] mb-[2.4rem] md:max-w-[25.7rem] md:mt-[4.1rem] lg:mt-0 xl:mt-[4rem] lg:max-w-[35.9302rem] 2xl:max-w-[53.96rem]' introClassName='tracking-[-0.0342rem] leading-[2.7rem] text-[1.8rem] max-w-[30.5rem] md:text-[2rem] md:leading-[3rem] md:tracking-[-0.036rem] md:max-w-[39.6rem] xl:max-w-[48.5rem] lg:text-[2.4rem] lg:leading-[3.6rem] 2xl:max-w-[58.3rem]' headerClassName='md:w-[80%] md:self-start lg:self-center lg:w-[50%] z-[100]' data-sentry-element="HeroValdProd" data-sentry-source-file="HumanTrakPage.client.tsx" />

      <ImageTextSecondary reversed id={page?.motionAnalysis?.items?.[0]?.scrollTo?.slice(1)} className='!overflow-visible py-[5rem] lg:py-[0]' background='bg-background-tertiary' title={page?.motionAnalysis?.items?.[0]?.title} titleClassName='lg:max-w-[60rem]' imgURL={page?.motionAnalysis?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.motionAnalysis?.items?.[0]?.mediaPrimary?.description} objectFit='contain' childrenElements={<>
            <RenderBodyCopy className='text-left lg:max-w-[78.2rem]' bodyCopy={page?.motionAnalysis?.items?.[0]?.bodyCopy?.json?.content} />
            <RenderButtons items={page?.motionAnalysis?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="HumanTrakPage.client.tsx" />

      <Container id={page?.intuitiveAndPowerful?.items?.[0]?.scrollTo?.slice(1)} className='md:flex md:flex-col' data-sentry-element="Container" data-sentry-source-file="HumanTrakPage.client.tsx">
        <SectionHeader className='justify-center lg:pb-[unset]' pretitle={page?.intuitiveAndPowerful?.items?.[0]?.preTitle} title={page?.intuitiveAndPowerful?.items?.[0]?.title} titleClassName='max-w-[51.6rem] text-[2.8rem] xl:text-[4.8rem] mb-[2.4rem] leading-[3.2rem] md:leading-[4.6rem] xl:leading-[5rem] font-[300]' align='center' data-sentry-element="SectionHeader" data-sentry-source-file="HumanTrakPage.client.tsx">
          <>
            <RenderBodyCopy className='max-w-[58.2rem] lg:max-w-[78.2rem]' bodyCopy={page?.intuitiveAndPowerful?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HumanTrakPage.client.tsx" />
            <RenderButtons className='justify-center' items={page?.intuitiveAndPowerful?.items?.[0]?.buttonCollection?.items} data-sentry-element="RenderButtons" data-sentry-source-file="HumanTrakPage.client.tsx" />
          </>
        </SectionHeader>
      </Container>

      <HotSpots data={page?.intuitiveAndPowerful?.items?.[0]} data-sentry-element="HotSpots" data-sentry-source-file="HumanTrakPage.client.tsx" />

      <div id={page?.context?.items?.[0]?.scrollTo?.slice(1)} className='relative bg-background-tertiary'>
        <ImageTextSecondary background='lg:!ml-[unset] lg:!mr-[unset] lg:!w-[100%]' pretitle={page?.context?.items?.[0]?.preTitle} title={page?.context?.items?.[0]?.title} titleClassName='lg:max-w-[35rem]' imgURL={page?.context?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.context?.items?.[0]?.mediaPrimary?.description} objectFit='contain' childrenElements={<>
              <RenderBodyCopy className='  lg:max-w-[50rem]' bodyCopy={page?.context?.items?.[0]?.bodyCopy?.json?.content} />
              <RenderButtons className='justify-center' items={page?.context?.items?.[0]?.buttonCollection?.items} />
            </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="HumanTrakPage.client.tsx" />
      </div>

      <ImageTextSecondary reversed id={page?.athletes?.items?.[0]?.scrollTo?.slice(1)} className='lg:!mb-[0] lg:!mt-[-6rem]' background='lg:!ml-[unset] lg:!mr-[unset] lg:!w-[100%]' pretitle={page?.athletes?.items?.[0]?.preTitle} title={page?.athletes?.items?.[0]?.title} titleClassName='lg:max-w-[62rem]' imgURL={page?.athletes?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.athletes?.items?.[0]?.mediaPrimary?.description} imgSecondClassName='relative lg:bottom-[-6rem] lg:left-[-6rem] lg:z-[-1]' objectFit='contain' childrenElements={<>
            <RenderBodyCopy className='  2xl:max-w-[78.2rem]' bodyCopy={page?.athletes?.items?.[0]?.bodyCopy?.json?.content} />
            <RenderButtons items={page?.athletes?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="HumanTrakPage.client.tsx" />

      <HumanTrakVideo id={page?.humanTrakKit?.items?.[0]?.scrollTo?.slice(1)} data={page?.humanTrakKit?.items?.[0]} data-sentry-element="HumanTrakVideo" data-sentry-source-file="HumanTrakPage.client.tsx" />

      <ImageTextSecondary id={page?.suitYouBusiness?.items?.[0]?.scrollTo?.slice(1)} background='lg:!ml-[unset] lg:!mr-[unset] lg:!w-[100%]' pretitle={page?.suitYouBusiness?.items?.[0]?.preTitle} title={page?.suitYouBusiness?.items?.[0]?.title} titleClassName='lg:max-w-[45rem]' imgURL={page?.suitYouBusiness?.items?.[0]?.mediaPrimary?.url} imgAlt={page?.suitYouBusiness?.items?.[0]?.mediaPrimary?.description} objectFit='contain' childrenElements={<>
            <RenderBodyCopy className='  2xl:max-w-[78.2rem]' bodyCopy={page?.suitYouBusiness?.items?.[0]?.bodyCopy?.json?.content} />
            <RenderButtons items={page?.suitYouBusiness?.items?.[0]?.buttonCollection?.items} />
          </>} data-sentry-element="ImageTextSecondary" data-sentry-source-file="HumanTrakPage.client.tsx" />

      <FeaturedProducts className='mt-[8rem] [&>header>h2]:max-w-[70rem]' data={page?.valdSystems?.items?.[0]} data-sentry-element="FeaturedProducts" data-sentry-source-file="HumanTrakPage.client.tsx" />

      <CallToAction id={page?.callToAction?.items?.[0]?.scrollTo?.slice(1)} title={page?.callToAction?.items?.[0]?.title} bodyCopy={page?.callToAction?.items?.[0]?.bodyCopy?.json?.content} buttons={page?.callToAction?.items?.[0]?.buttonCollection?.items} data-sentry-element="CallToAction" data-sentry-source-file="HumanTrakPage.client.tsx" />
    </>;
};
export default HumanTrakPageClient;